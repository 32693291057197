import styled from 'styled-components';
import FONTS from '@constants/fonts';
import SCREENS from '@constants/screens';
export var LinkList = styled.ol.withConfig({
    displayName: "sidenavstyled__LinkList",
    componentId: "k1medq-0"
})(["padding:0 0 0 0rem;margin:0 0 0 1rem;list-style:none;"]);
export var ListItem = styled.li.withConfig({
    displayName: "sidenavstyled__ListItem",
    componentId: "k1medq-1"
})(["color:var(--muted);&:not(:last-child){margin-bottom:0.5rem;}"]);
export var PageTitle = styled.p.withConfig({
    displayName: "sidenavstyled__PageTitle",
    componentId: "k1medq-2"
})(["font:", ";font-size:0.75rem;text-transform:uppercase;line-height:1.125;&+ ", "{margin-top:1.25rem;}"], FONTS.foundersGroteskRegular, LinkList);
export var StyledLink = styled.a.withConfig({
    displayName: "sidenavstyled__StyledLink",
    componentId: "k1medq-3"
})(["font:", ";font-size:0.875rem;line-height:1.25;color:var(--muted);text-decoration:none;&:hover{color:var(--primary) !important;}"], FONTS.foundersGroteskRegular);
export var Sidebar = styled.div.withConfig({
    displayName: "sidenavstyled__Sidebar",
    componentId: "k1medq-4"
})(["padding-top:6rem;padding-right:4rem;border-right:1px solid var(--gray);display:none;@media only screen and (min-width:", "){display:block;padding:5rem 3rem 0 0;}@media only screen and (min-width:", "){padding:6rem 4rem 0 0;}"], SCREENS.xl, SCREENS.xxl);
export var StyledSidebar = styled.div.withConfig({
    displayName: "sidenavstyled__StyledSidebar",
    componentId: "k1medq-5"
})(["padding:0rem;"]);
