import styled from 'styled-components';
import Button from '@components/button';
var Container = styled.div.withConfig({
    displayName: "download-overlaystyled__Container",
    componentId: "sc-1307ow2-0"
})(["position:relative;width:100%;height:100%;"]);
var StyledOverlay = styled.div.withConfig({
    displayName: "download-overlaystyled__StyledOverlay",
    componentId: "sc-1307ow2-1"
})(["position:absolute;top:0;left:0;display:flex;justify-content:center;align-items:center;width:100%;height:100%;background-color:var(--primary);opacity:0;transition:opacity 0.25s ease-in-out;&:hover{opacity:1;}"]);
var OutlineButton = styled(Button).withConfig({
    displayName: "download-overlaystyled__OutlineButton",
    componentId: "sc-1307ow2-2"
})(["border:1px solid var(--dark);background-color:transparent;color:var(--dark);&:hover{background-color:var(--dark);border-color:var(--dark);color:var(--primary);}"]);
export { Container, StyledOverlay, OutlineButton };
