import styled from 'styled-components';
import FONTS from '@constants/fonts';
import SCREENS from '@constants/screens';
export var GridWrapper = styled.div.withConfig({
    displayName: "keyword-gridstyled__GridWrapper",
    componentId: "sc-1csudrv-0"
})(["margin:0 auto;"]);
export var Container = styled.div.withConfig({
    displayName: "keyword-gridstyled__Container",
    componentId: "sc-1csudrv-1"
})(["border-top:1px solid var(--gray);padding:2rem 0 2rem;color:var(--white);display:grid;grid-template-columns:none;grid-column-gap:2rem;@media only screen and (min-width:", "){grid-template-columns:192px 1fr;}@media only screen and (min-width:", "){grid-template-columns:256px 1fr;}@media only screen and (min-width:", "){grid-template-columns:356px 1fr;}"], SCREENS.sm, SCREENS.md, SCREENS.xxl);
export var Title = styled.h3.withConfig({
    displayName: "keyword-gridstyled__Title",
    componentId: "sc-1csudrv-2"
})(["margin:0 0 0.75rem 0;font:", ";font-size:1.5rem;color:var(--white);line-height:1.125;@media only screen and (min-width:", "){margin:0;}"], FONTS.foundersGroteskLight, SCREENS.sm);
export var TextContainer = styled.div.withConfig({
    displayName: "keyword-gridstyled__TextContainer",
    componentId: "sc-1csudrv-3"
})(["margin:0;padding:0;font-size:1rem;line-height:1.5;color:var(--muted);@media only screen and (min-width:", "){font-size:1.125rem;}"], SCREENS.lg);
