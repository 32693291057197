import styled from 'styled-components';
import SCREENS from '@constants/screens';
var StyledGrid = styled.div.withConfig({
    displayName: "gridstyled__StyledGrid",
    componentId: "sc-14kr5cj-0"
})(["width:100%;display:grid;grid-column-gap:2rem;grid-row-gap:2rem;grid-template-columns:repeat(1,auto);@media only screen and (min-width:", "){grid-template-columns:", ";}@media only screen and (min-width:", "){grid-template-columns:", ";}"], SCREENS.md, function (_ref) {
    var itemsPerRow = _ref.itemsPerRow;
    return itemsPerRow > 2 ? 'repeat(2, auto)' : "repeat(" + itemsPerRow + ", 1fr)";
}, SCREENS.lg, function (_ref2) {
    var itemsPerRow = _ref2.itemsPerRow;
    return itemsPerRow > 3 ? 'repeat(3, auto)' : "repeat(" + itemsPerRow + ", 1fr)";
});
var GridItem = styled.div.withConfig({
    displayName: "gridstyled__GridItem",
    componentId: "sc-14kr5cj-1"
})(["display:flex;flex-direction:column;"]);
var StyledDescription = styled.p.withConfig({
    displayName: "gridstyled__StyledDescription",
    componentId: "sc-14kr5cj-2"
})(["margin:1rem 0 0;color:var(--muted);font-size:1rem;line-height:1.25;"]);
export { StyledGrid, GridItem, StyledDescription };
