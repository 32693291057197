import styled from 'styled-components';
import FONTS from '@constants/fonts';
var StyledButton = styled.button.withConfig({
    displayName: "buttonstyled__StyledButton",
    componentId: "sc-4puali-0"
})(["-webkit-appearance:none;-moz-appearance:none;background:transparent;border:1px solid var(--white);border-radius:var(--buttonBorderRadius);cursor:pointer;color:var(--white);font:", ";line-height:1;outline:0;padding:0rem 1.25rem;margin:0;height:", "rem;transition:all 0.25s ease-in-out;", " &:hover{color:var(--dark);background-color:var(--primary);border-color:var(--primary);}"], FONTS.foundersGroteskRegular, function (_ref) {
    var height = _ref.height;
    return height;
}, function (_ref2) {
    var isDisabled = _ref2.isDisabled;
    return isDisabled && "\n    cursor: auto;\n    background: var(--muted);\n  ";
});
export default StyledButton;
