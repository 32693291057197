import styled from 'styled-components';
import Fonts from '@constants/fonts';
import SCREENS from '@constants/screens';
import Button from '@components/button';
var CopyWrapper = styled.div.withConfig({
    displayName: "copystyled__CopyWrapper",
    componentId: "sht0xc-0"
})(["display:flex;column-gap:0rem;width:auto;@media only screen and (max-width:", "){width:100%;}"], SCREENS.sm);
var CopyInput = styled.input.withConfig({
    displayName: "copystyled__CopyInput",
    componentId: "sht0xc-1"
})(["outline:none;background:transparent;caret-color:transparent;min-width:auto;flex-grow:1;align-items:center;padding:0 0.75rem;height:2.25rem;margin:0;border:1px solid var(--gray);border-right:none;border-radius:var(--buttonBorderRadius);border-top-right-radius:0;border-bottom-right-radius:0;font:", ";text-overflow:ellipsis;overflow:hidden;white-space:nowrap;transition:all 0.125s ease-in-out;&:read-only{color:var(--muted);}&:focus{border-color:var(--white);}@media only screen and (min-width:", "){max-width:256px;}"], Fonts.foundersGroteskRegular, SCREENS.sm);
var StyledButton = styled(Button).withConfig({
    displayName: "copystyled__StyledButton",
    componentId: "sht0xc-2"
})(["border-top-left-radius:0;border-bottom-left-radius:0;padding:0 1rem;"]);
export { CopyWrapper, CopyInput, StyledButton };
