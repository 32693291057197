import styled from 'styled-components';
import Copy from '@components/copy';
import CopyIcon from '@components/svgs/copy';
import StyledButton from '@components/button/button.styled';
import { CopyInput } from '@components/copy/copy.styled';
import FONTS from '@constants/fonts';
import SCREENS from '@constants/screens';
export var ColorWrapper = styled.div.withConfig({
    displayName: "colorstyled__ColorWrapper",
    componentId: "f7mguf-0"
})(["width:100%;height:100%;min-height:224px;max-height:272px;padding:1.5rem 1.25rem;background:", ";display:flex;flex-wrap:wrap;border-radius:var(--colorBorderRadius);border:1px solid var(--gray);@media only screen and (min-width:", "){min-height:272px;}"], function (_ref) {
    var HEX = _ref.HEX;
    return HEX;
}, SCREENS.lg);
export var ColorName = styled.p.withConfig({
    displayName: "colorstyled__ColorName",
    componentId: "f7mguf-1"
})(["width:100%;align-self:flex-start;font:", ";font-size:1.25rem;line-height:1.25rem;color:", ";margin-bottom:1.5rem;"], FONTS.foundersGroteskRegular, function (_ref2) {
    var textColor = _ref2.textColor;
    return textColor;
});
export var PropertiesWrapper = styled.ul.withConfig({
    displayName: "colorstyled__PropertiesWrapper",
    componentId: "f7mguf-2"
})(["list-style:none;margin:0;padding:0;align-self:flex-end;"]);
export var ColorProperties = styled.li.withConfig({
    displayName: "colorstyled__ColorProperties",
    componentId: "f7mguf-3"
})(["color:", ";height:1.5rem;display:flex;align-items:center;width:max-content;&:not(:last-child){margin-bottom:0.5rem;}"], function (_ref3) {
    var textColor = _ref3.textColor;
    return textColor;
});
export var ColorProperty = styled.div.withConfig({
    displayName: "colorstyled__ColorProperty",
    componentId: "f7mguf-4"
})(["font:", ";font-size:0.875rem;width:3rem;"], FONTS.foundersGroteskMonoRegular);
export var ColorValue = styled.div.withConfig({
    displayName: "colorstyled__ColorValue",
    componentId: "f7mguf-5"
})(["font:", ";font-size:0.8125rem;"], FONTS.foundersGroteskRegular);
export var StyledCopy = styled(Copy).withConfig({
    displayName: "colorstyled__StyledCopy",
    componentId: "f7mguf-6"
})(["width:auto;margin-right:1rem;", "{position:fixed;left:-10000px;top:-10000px;}", "{padding:0;width:1.5rem;height:1.5rem;border:1px solid ", ";border-radius:var(--buttonBorderRadius);background:transparent;display:flex;justify-content:center;align-items:center;svg *{transition:all 0.25s ease-in-out;}&:hover{background-color:", ";border-color:", ";svg *{fill:", ";}}}"], CopyInput, StyledButton, function (_ref4) {
    var textColor = _ref4.textColor;
    return textColor;
}, function (_ref5) {
    var textColor = _ref5.textColor;
    return textColor;
}, function (_ref6) {
    var textColor = _ref6.textColor;
    return textColor;
}, function (_ref7) {
    var baseColor = _ref7.baseColor;
    return baseColor;
});
export var StyledCopyIcon = styled(CopyIcon).withConfig({
    displayName: "colorstyled__StyledCopyIcon",
    componentId: "f7mguf-7"
})(["width:0.75rem;height:0.75rem;fill:", ";"], function (_ref8) {
    var color = _ref8.color;
    return color;
});
