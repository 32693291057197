export var hexToRgb = function hexToRgb(hex) {
    var arrBuff = new ArrayBuffer(4);
    var vw = new DataView(arrBuff);
    vw.setUint32(0, parseInt(hex.slice(1), 16), false);
    var arrByte = new Uint8Array(arrBuff);
    return arrByte[1] + ", " + arrByte[2] + ", " + arrByte[3];
};
var rgbTocmyk = function rgbTocmyk(r, g, b) {
    // remove spaces from input RGB values, convert to int
    var parsedR = parseInt(r.replace(/\s/g, ''), 10);
    var parsedG = parseInt(g.replace(/\s/g, ''), 10);
    var parsedB = parseInt(b.replace(/\s/g, ''), 10); // BLACK
    if (parsedR === 0 && parsedG === 0 && parsedB === 0) {
        return '0 / 0 / 0 / 1';
    }
    var computedC = 1 - parsedR / 255;
    var computedM = 1 - parsedG / 255;
    var computedY = 1 - parsedB / 255;
    var minCMY = Math.min(computedC, Math.min(computedM, computedY));
    computedC = Math.round((computedC - minCMY) / (1 - minCMY) * 100);
    computedM = Math.round((computedM - minCMY) / (1 - minCMY) * 100);
    computedY = Math.round((computedY - minCMY) / (1 - minCMY) * 100);
    var computedK = Math.round(minCMY * 100);
    return computedC + "% / " + computedM + "% / " + computedY + "% / " + computedK + "%";
};
export var getColorPropsByHex = function getColorPropsByHex(HEX) {
    var RGB = hexToRgb(HEX);
    var _RGB$split = RGB.split(', '), r = _RGB$split[0], g = _RGB$split[1], b = _RGB$split[2];
    var CMYK = rgbTocmyk(r, g, b);
    return {
        HEX: HEX,
        RGB: RGB,
        CMYK: CMYK
    };
};
