import styled from 'styled-components';
import Grid from '@components/grid';
import SCREENS from '@constants/screens';
import { ImageWrapper } from '@components/image/image.styled';
import { GridItem } from '@components/grid/grid.styled';
import FONTS from '@constants/fonts';
import Copy from '@components/copy';
var Wrapper = styled.div.withConfig({
    displayName: "modulestyled__Wrapper",
    componentId: "sc-173zf82-0"
})(["padding:4rem 0rem 4rem 0rem;margin:0 auto;&:not(:last-of-type){border-bottom:1px solid var(--gray);position:relative;&::after{content:'';display:block;position:relative;width:100%;height:0;border-bottom:1px solid var(--gray);position:absolute;left:100%;bottom:-1px;}}@media only screen and (min-width:", "){padding:5rem 0;}@media only screen and (min-width:", "){padding:5rem 0;}@media only screen and (min-width:", "){padding:5rem 0 5rem 5rem;}@media only screen and (min-width:", "){padding:6rem 0 6rem 6rem;}"], SCREENS.md, SCREENS.lg, SCREENS.xl, SCREENS.xxl);
var Info = styled.div.withConfig({
    displayName: "modulestyled__Info",
    componentId: "sc-173zf82-1"
})(["margin:0 0 3rem 0;"]);
var SectionMetaContainer = styled.div.withConfig({
    displayName: "modulestyled__SectionMetaContainer",
    componentId: "sc-173zf82-2"
})(["display:flex;flex-direction:column;justify-content:space-between;align-items:flex-start;flex-wrap:wrap;margin-bottom:2rem;@media only screen and (min-width:", "){flex-direction:row;margin-bottom:3rem;}"], SCREENS.md);
var StyledCopy = styled(Copy).withConfig({
    displayName: "modulestyled__StyledCopy",
    componentId: "sc-173zf82-3"
})(["margin-top:2.25rem;@media only screen and (min-width:", "){margin-top:0;}"], SCREENS.md);
var Summary = styled.div.withConfig({
    displayName: "modulestyled__Summary",
    componentId: "sc-173zf82-4"
})(["width:100%;margin-bottom:3rem;@media only screen and (min-width:", "){margin-bottom:3rem;width:80%;}@media only screen and (min-width:", "){margin-bottom:3rem;max-width:1000px;}"], SCREENS.lg, SCREENS.lg);
var Content = styled.div.withConfig({
    displayName: "modulestyled__Content",
    componentId: "sc-173zf82-5"
})(["", ""], function (_ref) {
    var isWithNotes = _ref.isWithNotes;
    return isWithNotes && "\n    max-width: 100%;\n    display: flex;\n    flex-direction: column-reverse;\n    align-items: flex-start;\n\n    @media only screen and (min-width: " + SCREENS.lg + ") {\n      flex-direction: row-reverse;\n      align-items: stretch;\n    }\n  ";
});
var ColorsGrid = styled(Grid).withConfig({
    displayName: "modulestyled__ColorsGrid",
    componentId: "sc-173zf82-6"
})(["width:100%;margin:0 auto;grid-template-columns:1fr;grid-gap:1.5rem;justify-content:left;@media only screen and (min-width:", "){grid-gap:2rem;grid-template-columns:repeat(2,auto);}@media only screen and (min-width:", "){grid-template-columns:repeat(3,auto);}@media only screen and (min-width:", "){grid-template-columns:repeat(4,auto);}"], SCREENS.md, SCREENS.lg, SCREENS.xxl);
var notesMaxWidth = 18.75;
var notesRightMargin = 3.375;
var Notes = styled.div.withConfig({
    displayName: "modulestyled__Notes",
    componentId: "sc-173zf82-7"
})(["width:100%;margin:3rem 0 0;@media only screen and (min-width:", "){align-self:flex-start;}@media only screen and (min-width:", "){flex:0 0 16rem;margin:0 0 0 2rem;align-self:flex-start;}@media only screen and (min-width:", "){flex:0 0 14rem;margin:0 0 0 2rem;align-self:flex-start;}@media only screen and (min-width:", "){flex:0 0 16rem;}+ ", "{grid-gap:1rem;grid-row-gap 1rem;@media only screen and (min-width:", "){align-content:flex-start;grid-template-columns:repeat(2,auto);}@media only screen and (min-width:", "){grid-gap:1.25rem;}@media only screen and (min-width:", "){grid-gap:1.5rem;}}h4{font:", ";font-size:0.8125rem;text-transform:uppercase;line-height:1.125;color:var(--white);margin:0;&+ p{margin-top:0.5rem;}}p{color:var(--muted);line-height:1.25;&+ p{margin-top:1rem;}&+ h4{margin-top:2rem;}}"], SCREENS.md, SCREENS.lg, SCREENS.xl, SCREENS.xxl, ColorsGrid, SCREENS.md, SCREENS.xl, SCREENS.xxl, FONTS.foundersGroteskRegular);
export var RichTextGrid = styled(Grid).withConfig({
    displayName: "modulestyled__RichTextGrid",
    componentId: "sc-173zf82-8"
})(["grid-column-gap:2.5rem;grid-row-gap:2.5rem;h4{margin:0 0 1rem 0;font-size:1.125rem;line-height:1.125;color:var(--white);}p{line-height:1.25;&+ p{margin-top:1rem;}}", "{margin-bottom:1.25rem;}", "{border-radius:var(--colorBorderRadius);}@media only screen and (min-width:", "){max-width:calc(100% - ", "rem - ", "rem);max-width:100%;}"], ImageWrapper, GridItem, SCREENS.md, notesMaxWidth, notesRightMargin);
export var RichTextContentGrid = styled(Grid).withConfig({
    displayName: "modulestyled__RichTextContentGrid",
    componentId: "sc-173zf82-9"
})(["p{width:100%;margin:0 auto;line-height:1.5;@media only screen and (min-width:", "){max-width:480px;}}p + p{margin-top:1.5rem;}ol{margin:0 auto;@media only screen and (min-width:", "){max-width:480px;}}h3{p{max-width:100%;}}img{width:100%;}[data-oembed]{position:relative;overflow:hidden;width:100%;padding-top:56.25%;iframe{position:absolute;top:0;left:0;bottom:0;right:0;width:100%;height:100%;}}"], SCREENS.lg, SCREENS.lg);
var ButtonWrapper = styled.div.withConfig({
    displayName: "modulestyled__ButtonWrapper",
    componentId: "sc-173zf82-10"
})(["width:max-content;margin:3rem auto 0;"]);
var MediaWrap = styled.div.withConfig({
    displayName: "modulestyled__MediaWrap",
    componentId: "sc-173zf82-11"
})(["width:100%;"]);
export { Wrapper, Info, SectionMetaContainer, StyledCopy, Summary, MediaWrap, Content, Notes, ColorsGrid, ButtonWrapper };
