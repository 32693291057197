import styled from 'styled-components';
import FONTS from '@constants/fonts';
export var TooltipContainer = styled.div.withConfig({
    displayName: "tooltipstyled__TooltipContainer",
    componentId: "sc-1hof9d5-0"
})(["position:relative;font:", ";font-size:0.875rem;line-height:1.125;text-align:center;letter-spacing:-0.03125rem;"], FONTS.foundersGroteskMonoRegular);
export var TooltipBox = styled.div.withConfig({
    displayName: "tooltipstyled__TooltipBox",
    componentId: "sc-1hof9d5-1"
})(["white-space:nowrap;position:absolute;background:var(--black);border:1px solid var(--black);color:var(--white);padding:0.5rem 0.875rem;border-radius:0.3125rem;top:calc(100% + 0.3125rem);left:50%;transform:translate(-50%);display:", ";z-index:1;"], function (_ref) {
    var isVisible = _ref.isVisible;
    return isVisible ? 'block' : 'none';
});
export var TooltipArrow = styled.span.withConfig({
    displayName: "tooltipstyled__TooltipArrow",
    componentId: "sc-1hof9d5-2"
})(["position:absolute;top:-10px;left:50%;transform:translateX(-50%);border-width:0.3125rem;border-style:solid;border-color:transparent transparent var(--black) transparent;"]);
