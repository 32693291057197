var getContrastYIQ = function getContrastYIQ(hex) {
    var threshold = 130;
    var cutHex = function cutHex(h) {
        return h.charAt(0) === '#' ? h.substring(1, 7) : h;
    };
    var hexToR = function hexToR(h) {
        return parseInt(cutHex(h).substring(0, 2), 16);
    };
    var hexToG = function hexToG(h) {
        return parseInt(cutHex(h).substring(2, 4), 16);
    };
    var hexToB = function hexToB(h) {
        return parseInt(cutHex(h).substring(4, 6), 16);
    };
    var hRed = hexToR(hex);
    var hGreen = hexToG(hex);
    var hBlue = hexToB(hex);
    var cBrightness = (hRed * 299 + hGreen * 587 + hBlue * 114) / 1000;
    if (cBrightness > threshold) {
        return 'var(--black)';
    }
    return 'var(--white)';
};
export default getContrastYIQ;
