import styled from 'styled-components';
import SCREENS from '@constants/screens';
export var SidebarWrapper = styled.div.withConfig({
    displayName: "pagestyled__SidebarWrapper",
    componentId: "d9tx8r-0"
})(["width:100%;display:grid;grid-gap:0;grid-template-columns:1fr;@media only screen and (min-width:", "){grid-template-columns:224px 1fr;}"], SCREENS.xl);
export var ModuleContent = styled.div.withConfig({
    displayName: "pagestyled__ModuleContent",
    componentId: "d9tx8r-1"
})(["display:block;"]);
